// ======================================================================
// Colors
// ======================================================================

$primary-light: #F52E5D;
$primary: #EA1B64;
$primary-dark: #D5076E;
$primary-darker: #AF007B;
$primary-gradient: linear-gradient(90deg, $primary-dark, $primary-light);

$secondary: #0D3B66;

$text: #111;
$grey: #828282;
$light-grey: lighten($grey, 40%);
$dark-grey: darken($grey, 25%);

// ======================================================================
// Font
// ======================================================================

$base-font-family: "Maitree", Arial, sans-serif;
$title-font-family: "Athiti", Arial, sans-serif;

$base-font-weight: 500;
$display-font-weight: 300;
$title-font-weight: 600;

$base-line-height: 1.3;
$title-line-height: 1;
$paragraph-line-height: 1.8;

$base-font-size: 18px;

$display-font-size: 6rem;
$title-font-size: 2rem;
$subtitle-font-size: 1.5rem;
$subtext-font-size: 0.8rem;

$display-mobile-font-size: 2rem;
$title-mobile-font-size: 1.5rem;

// ======================================================================
// Spacing
// ======================================================================

$gap-s: 10px;
$gap-m: 20px;
$gap-l: 40px;
$gap-xl: 40px;

@mixin padding($size: m){
  @if $size == s { padding: $gap-s }
  @if $size == m { padding: $gap-m }
  @if $size == l { padding: $gap-l }
  @if $size == xl { padding: $gap-xl }
}

@mixin padding-wide($size: m){
  @if $size == xs { padding: 0 $gap-s }
  @if $size == s { padding: $gap-s $gap-m }
  @if $size == m { padding: $gap-m $gap-l }
  @if $size == l { padding: $gap-l $gap-xl }
}

@mixin margin-inline($size: m){
  @if $size == s { margin-right: $gap-s }
  @if $size == m { margin-right: $gap-m }
  @if $size == l { margin-right: $gap-l }
  @if $size == xl { margin-right: $gap-xl }
}

@mixin margin-stack($size: m){
  @if $size == s { margin-bottom: $gap-s }
  @if $size == m { margin-bottom: $gap-m }
  @if $size == l { margin-bottom: $gap-l }
  @if $size == xl { margin-bottom: $gap-xl }
}

// ======================================================================
// Responsive mixins
// ======================================================================

$three-columns: 1020px;
$one-column: 510px;

@mixin media-query($device: $one-column) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// ======================================================================
// Layout mixins
// ======================================================================

@mixin flex($flow, $align, $justify){
  display: flex;
  flex-flow: $flow;
  align-items: $align;
  justify-content: $justify;
}

// ======================================================================
// Appearance mixins
// ======================================================================

@mixin base-font{
  font-family: $base-font-family;
  line-height: $base-line-height;
  font-weight: $base-font-weight;
}

@mixin display-font{
  font-family: $base-font-family;
  line-height: $title-line-height;
  font-weight: $display-font-weight;
}

@mixin title-font{
  font-family: $title-font-family;
  line-height: $title-line-height;
  font-weight: $title-font-weight;
}

@mixin title-size{
  font-size: $title-font-size;
  @include media-query{ font-size: $title-mobile-font-size; }
}

@mixin border-radius($size: m){
  @if $size == s { border-radius: 5px; }
  @if $size == m { border-radius: 12px; }
  @if $size == l { border-radius: 18px; }
}

@mixin transition-normal{
  transition: all .2s ease;
}

@mixin transition-fast{
  transition: all .1s ease;
}

@mixin transition-elastic{
  transition: all .2s cubic-bezier(.14,.57,.35,1.24);
}

@mixin clickable{
  cursor: pointer;
}

@mixin fade-out-if-hidden($elastic: false){
  @if $elastic{
    @include transition-elastic;
  } @else {
    @include transition-normal;
  }

  opacity: 1;
  &--hidden{ opacity: 0; }
}

// ======================================================================
// Preset classes
// ======================================================================

.display-responsive{
  @include display-font;
  font-size: $display-font-size;

  @include media-query($three-columns){
    font-size: $display-mobile-font-size;
  }
}

.title{
  @include title-font;
  font-size: $title-font-size;
  margin: $gap-l 0 $gap-m 0;
}

.title-with-subtext{
  @include title-font;
  font-size: $title-font-size;
}

.subtitle{
  @include title-font;
  @include margin-stack;
  font-size: $subtitle-font-size;
  font-style: italic;
}

.subtext{
  @include margin-stack;
  font-size: $subtext-font-size;
  color: $grey;
  font-style: italic;
}

.leftBar{
  @include flex(row, center, flex-start);
  @include margin-stack;
  width: 100%;

  > *:not(:last-child){ @include margin-inline(s); }
}

.centerBar{
  @include flex(row, center, space-around);
  @include margin-stack;
  width: 100%;

  > *:not(:last-child){ margin-right: 0; }
}

.rightBar{
  @include flex(row, center, flex-end);
  @include margin-stack;
  width: 100%;

  > *:not(:last-child){ @include margin-inline(s); }
}

.button{
  @include flex(row, center, center);
  @include padding-wide(s);
  @include border-radius(l);
  @include transition-normal;
  position: relative; top: 0;
  text-transform: uppercase;
  font-size: $base-font-size;
  color: white;
  background: $primary-gradient;
  border: none;
  box-shadow: rgba($primary-darker, 0.4) 0 0 0;

  span{ @include margin-inline(s); }

  &:not(:last-child){ @include margin-inline(s); }

  &:hover, &:focus{
    outline: none;
    box-shadow: rgba($primary-darker, 0.4) 0 6px 20px;
  }

  &:active{
    top: 1px;
  }
}

.chip{
  @include padding-wide(xs);
  @include border-radius;
  font-size: $subtext-font-size;
  color: white;
  display: inline-block;
  background: $primary;
}

.table{
  @include margin-stack;
  border-collapse: collapse;

  th{
    @include padding(s);
    text-align: left;
  }

  td{
    @include padding(s);
    border-top: 1px $light-grey solid;
  }
}
