// ======================================================================
// Basic CSS reset
// ======================================================================

html, body, ul, ol, li{
  margin: 0;
  padding: 0;
  font-size: $base-font-size;
}

p{
  margin: 0;
  padding: 0;

  &:not(:last-child){
    @include margin-stack;
  }
}

body {
  @include base-font;
  background-color: white;
}

h1, h2, h3, h4, h5, h6 {
  @include title-font;
  font-size: $base-font-size;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: $primary;
}

 button, input{
   @include base-font;
   font-size: $base-font-size;
 }
