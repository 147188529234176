@charset "utf-8";

@import
  "theme/variables",
  "theme/base";

body{
  height: 100vh;
  overflow: hidden;
  background: white;
}

body::after{
  @include border-radius(l);
  content: "";
  position: absolute;
  top: 10vh; bottom: 10vh; left: 5vw; right: 5vw;
  z-index: -2;
  background: $secondary;
  box-shadow: $primary 0 0 0 2vw;

  @include media-query{
    top: -10vh; bottom: -10vh; left: 2vw; right: 2vw;
  }
}

.home{
  @include flex(column, stretch, space-between);
  overflow: hidden;
  height: 100vh;

  &-blurb{
    @include flex(column, center, space-around);
    text-align: center;
  }

  &-logo{
    position: relative;
    line-height: 1;
    font-size: 600px;
    font-size: 35vw;
    letter-spacing: -1vw;
    color: white;
    animation: shadowGradient infinite 2s;
    --shadowHeight: 1.5vw;
    --shadowWidth: 0.8vw;
    text-shadow: $primary-dark var(--shadowWidth) var(--shadowHeight),
      $primary-darker calc(0vw - var(--shadowWidth)) calc(var(--shadowHeight) * 2),
      $primary-dark var(--shadowWidth) calc(var(--shadowHeight) * 3),
      $primary-darker calc(0vw - var(--shadowWidth)) calc(var(--shadowHeight) * 4);
  }

  &-menu{
    @include flex(row wrap, center, center);
    margin-bottom: 10vh;
    line-height: 1;

    @include media-query{
      @include flex(column, center, flex-start);
      margin-bottom: 0;
    }
  }

  &-menuItem{
    @include padding-wide;
    @include transition-normal;
    margin: 20px;
    color: white;
    font-size: 32px;
    position: relative;
    text-shadow: $primary 8px 0, $primary -8px 0;

    &::after{
      @include transition-normal;
      @include border-radius;
      content: "";
      position: absolute;
      top: 55%; left: 0; bottom: 0; right: 0;
      background: $primary;
      z-index: -1;
      transform: rotate(-1deg);
    }

    &:hover{
      text-shadow: $primary-darker 8px 0, $primary-darker -8px 0;

      &::after {
          background: $primary-darker;
          top: 0%;
        }
    }
  }
}

@keyframes shadowGradient{
  0%{
    text-shadow: $primary-light var(--shadowWidth) var(--shadowHeight),
        $primary calc(0vw - var(--shadowWidth)) calc(var(--shadowHeight) * 2),
        $primary-dark var(--shadowWidth) calc(var(--shadowHeight) * 3),
        $primary-darker calc(0vw - var(--shadowWidth)) calc(var(--shadowHeight) * 4);
  }
  75%{
    text-shadow: $primary var(--shadowWidth) var(--shadowHeight),
        $primary-dark calc(0vw - var(--shadowWidth)) calc(var(--shadowHeight) * 2),
        $primary-darker var(--shadowWidth) calc(var(--shadowHeight) * 3),
        $primary-light calc(0vw - var(--shadowWidth)) calc(var(--shadowHeight) * 4);
  }
  50%{
    text-shadow: $primary-dark var(--shadowWidth) var(--shadowHeight),
        $primary-darker calc(0vw - var(--shadowWidth)) calc(var(--shadowHeight) * 2),
        $primary-light var(--shadowWidth) calc(var(--shadowHeight) * 3),
        $primary calc(0vw - var(--shadowWidth)) calc(var(--shadowHeight) * 4);
  }
  25%{
    text-shadow: $primary-darker var(--shadowWidth) var(--shadowHeight),
        $primary-light calc(0vw - var(--shadowWidth)) calc(var(--shadowHeight) * 2),
        $primary var(--shadowWidth) calc(var(--shadowHeight) * 3),
        $primary-dark calc(0vw - var(--shadowWidth)) calc(var(--shadowHeight) * 4);
  }
  100%{
    text-shadow: $primary-light var(--shadowWidth) var(--shadowHeight),
        $primary calc(0vw - var(--shadowWidth)) calc(var(--shadowHeight) * 2),
        $primary-dark var(--shadowWidth) calc(var(--shadowHeight) * 3),
        $primary-darker calc(0vw - var(--shadowWidth)) calc(var(--shadowHeight) * 4);
  }
}
